import React from 'react';
import { Link } from 'react-router-dom';


import CoursesList from './coursesList';
import "./home.css"
import NewVideoslist from './home_items/newVideoslist';
import Strip from './strip';
import ShowMe from './home_items/showMe';
import BenefitsBoxes from './home_items/benefitsBoxes';
import HomeFeatures from './home_items/homeFeatures';
import Recommend from './home_items/recommend';

function Home(props) {



  return (
    <main className='home'>
      <Strip />
      <div className='top-border-home ' style={{ backgroundImage: "url(images/header_border.png)" }}>
      <ShowMe />
      </div>
      <div className='top-border-home mt-4 pt-5' style={{ backgroundImage: "url(images/header_border.png)" }}>
        <HomeFeatures />
        <div className='text-center my-5'>
          <Link to="/courses_vod/#" className='display-6 text-center text-white btn btn-info' style={{ fontSize: "2em", backgroundColor: "#5000C8", border: "1px solid black" }}>לרשימת הקורסים המלאה</Link>
        </div>
      </div>
      <div className='top-border-home mt-4 pt-5 mb-5 pb-4' style={{ backgroundImage: "url(images/header_border.png)" }}>

        <BenefitsBoxes />
      </div>
      
      <div className='top-border-home mt-4 pt-5' style={{ backgroundImage: "url(images/header_border.png)" }}>
        <CoursesList />

      </div>
      {/* <NewVideoslist cssWidth="col-md-3" /> */}

      <div className='text-center mb-5'>
        <Link to="/courses_vod/#" className='display-6 text-center text-white btn btn-info' style={{ fontSize: "2em", backgroundColor: "#5000C8", border: "1px solid black" }}>לרשימת הקורסים המלאה</Link>
      </div>
      <div className='top-border-home my-4 mb-5 pt-5' style={{ backgroundImage: "url(images/header_border.png)" }}>
        <Recommend />
        
      </div>
      <div className='text-center pb-5'>
        <img src="images/all_logo_list.png" className='col-md-8 col-12' alt="לוגו בנושא MEAN STACK" />
        <div className='text-center my-5'>
          <Link to="/courses_vod/#" className='display-6 text-center text-white btn btn-info' style={{ fontSize: "2em", backgroundColor: "#5000C8", border: "1px solid black" }}>לרשימת הקורסים המלאה</Link>
        </div>
      </div>
    </main>
  )
}

export default Home