import React from 'react'

export default function BenefitsBoxes() {
  return (
    <div className='container mt-5' style={{minHeight:"40px"}}>
      <div className='row text-center'>
        <div className='col-sm-6 col-lg-3 p-2'>
          <div className='p-2 box_benefit' style={{borderTopColor:"#A76AFF"}}>
            <p className='h1' style={{color:"#A76AFF"}}>24+</p>
            <h3>קורסים</h3>
          </div>
        </div>

        <div className='col-sm-6 col-lg-3 p-2'>
          <div className='p-2 box_benefit' style={{borderTopColor:"#C4E620"}}>
            <p className='h1' style={{color:"#C4E620"}}>15+</p>
            <h3>שנות ניסיון</h3>
          </div>
        </div>

        <div className='col-sm-6 col-lg-3 p-2'>
          <div className='p-2 box_benefit' style={{borderTopColor:"#FF99C8"}}>
            <p className='h1' style={{color:"#FF99C8"}}>6K+</p>
            <h3>מינויים ביוטיוב</h3>
          </div>
        </div>

        <div className='col-sm-6 col-lg-3 p-2'>
          <div className='p-2 box_benefit' style={{borderTopColor:"#FFA905"}}>
            <p className='h1' style={{color:"#FFA905"}}>2K+</p>
            <h3>סטודנטים פרונטלים</h3>
          </div>
        </div>
      </div>
    </div>
  )
}
