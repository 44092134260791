import React, { useEffect } from 'react';
import { useState } from 'react';
import { PayPalButton } from "react-paypal-button-v2";
import { Link, useParams } from 'react-router-dom';
import { prices_ar } from "../../data/prices_json"
import { API_URL, doApiGet, doApiMethod } from '../../services/apiService';
import AuthComp from '../authComp';
import ScrollTop from '../misc/scrollTop';

function PayPage(props) {

  let params = useParams()
  const [shabat, setShabat] = useState(false)
  const [check, setCheck] = useState(false)
  let ind = params.payId || 0;

  useEffect(() => {
    let url = API_URL + "/shabat";
    doApiGet(url)
      .then(data => {
        console.log(data);
        if (data.if_shabat == "yes") {
          setShabat(true);
        }
      }

      )
      .catch(err => {
        console.log(err)
      })
  }, [])


  return (
    <main className='bg-white'>
      <div className='container py-4 bg-white text-black' style={{ minHeight: "75vh" }}>
        <ScrollTop />
        <AuthComp />
        <div className="row">
          {shabat ? <div><h2 className='text-dark bg-info'> חזור אלינו במוצאי החג/שבת או צור אתנו קשר בווטסאפ </h2><p>* במידה והודעה זו ממשיכה להופיע לאחר צאת /שבת/החג צור עמנו קשר</p> </div>:
            <div className="col-md-7">
              <h1>קופה לתשלום</h1>
              <h3>בחר אמצעי תשלום:</h3>
              {!check ? <img onClick={() => {
                alert("יש לאשר את התקנון מצד שמאל בסימון וי לפני הרכישה")
              }} src="/images/paypal_cancel.jpg" alt="יש לאשר את התקנון לפני הרכישה" className="img-fluid" /> :
                <PayPalButton
                  currency="ILS"
                  amount={prices_ar[ind].price}
                  onSuccess={(details, data) => {
                    console.log("Transaction completed by ", details);
                    console.log("data ", data);
                    if (data.orderID) {
                      console.log("aaaa", data);
                      let p = prices_ar[ind];
                      let url = API_URL + "/users/user_pay"
                      let body = {
                        months: p.months,
                        moneyPaid: p.price,
                        token_id: data.facilitatorAccessToken,
                        order_id: data.orderID,
                        pay_kind: "Paypal"
                      }
                      console.log(body)
                      doApiMethod(url, "POST", body)
                        .then(data2 => {
                          console.log(data2);
                          if (data2.modifiedCount) {
                            alert("הרשמה הצליחה, כדי שהמשתמש פרימיום יכנס לתוקף לחץ על התנתק והתחבר שוב מחדש, תתחדש!")
                            window.location.href = "/logout"
                            // TODO: Send to me here email
                          }
                        })
                    }
                    // props.successFunc(data.orderID);
                  }}

                  onCancel={(err) => {
                    // אם המשתמש ביטל
                    console.log(err);
                    alert("תהליך התשלום הסתיים לפני האישור, נסה שוב")
                  }}

                  // wi -> אמיתי
                  options={{
                    // clientId: "ARKelooSHrl5ImGtOoH_p3oR6AxrhkNERIyIz8DuWXGtzlkWIXKbz1-UnJO4Hzh3wFjxsuhG07V2e4Vd"
                    clientId: "AY7vKV2e5Kzkd8t5h83p7YevvaPbGnH5bkP6J3dbYRUKYCOVbc4_ilqvwnwCP_LNzcNP02gdBXBV23Wi"
                  }}
                />
              }
              <small>
                * לאחר הרכישה תועבר לעמוד התחברות.
                <br />
                *
                במידה ואינך מצליח לבצע תשלום , כנס לדף הבא
                לקבלת אפשרויות תשלום אחרות
                -
                <Link to="/forPay" style={{ color: "blue" }}>
                  לחץ כאן
                </Link>

              </small>
            </div>}
          <div className="col-md-5 p-3">
            <div className='p-3 border shadow-sm'>
              <h2>סיכום הזמנה:</h2>
              <p className='float-start'>{prices_ar[ind].price} ש"ח</p>
              <p className='m-0'>{prices_ar[ind].name}:</p>
              <small>{prices_ar[ind].info}</small>
              <hr />
              <p className='float-start'>{prices_ar[ind].price} ש"ח</p>
              <p>סה"כ (כולל מע"מ):</p>
              <small>

                *  שליחת חשבונית/קבלה תתבצע עד 5 ימי עבודה ממועד אישור העסקה
                <br /> ותשלח למייל המשתמש באתר מאנקיס פרימיום.
              </small>
              <br />
              <input onClick={
                () => { setCheck(!check) }
              } type="checkbox" /> -
              <a href="https://monkeys.co.il/terms/" target="_blank" style={{ color: "blue" }}>
                <small> בעת רכישה הינך מאשר את תקנון האתר</small>
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default PayPage