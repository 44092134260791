// import logo from './logo.svg';
import './App.css';
import AppVod from './comps_vod/appVod';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppPlayer from './comps_vod/player/appPlayer';
import Home from "./comps_vod/home/home";
import AppCourses from './comps_vod/courses/appCourses';
import Login from './comps_vod/home/login';
import WelcomeBack from './comps_vod/home/welcomeBack';
import Logout from './comps_vod/home/logout';
import Premium from './comps_vod/home/premium';
import UserPage from './comps_vod/userInfo/userPage';
import StudentInfo from './comps_vod/misc/studentInfo';
import ForPay from './comps_vod/misc/forPay';
import PayPage from './comps_vod/userInfo/payPage';
import Login2 from './comps_vod/home/login2';

function App() {
  return (

    <Router>
      <Routes>
        <Route path="/" element={<AppVod />} >
          <Route index element={<Home />} ></Route>
          <Route path="/courses_vod" element={<AppCourses />} ></Route>
          <Route path="/player" element={<AppPlayer />} ></Route>
          <Route path="/player/:courseId" element={<AppPlayer />} ></Route>
          <Route path="/player/:courseId/:videoId" element={<AppPlayer />} ></Route>
          <Route path="/login" element={<Login2 />} ></Route>
          <Route path="/login2" element={<Login />} ></Route>
          <Route path="/welcome" element={<WelcomeBack />} ></Route>
          <Route path="/welcome-back" element={<WelcomeBack />} ></Route>
          <Route path="/user-page" element={<UserPage />} ></Route>
          <Route path="/premium" element={<Premium />} ></Route>
          <Route path="/s-info" element={<StudentInfo />} ></Route>
          <Route path="/forPay" element={<ForPay />} ></Route>
          <Route path="/logout" element={<Logout />} ></Route>
          <Route path="/pay/:payId" element={<PayPage />} ></Route>
        </Route>
      </Routes>
      {/* <AppVod /> */}
    </Router>

  );
}

export default App;
